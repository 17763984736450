/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, SeparateLine, SeparateLineWrap, Divider, Text, Image, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-19szsm css-42e4bw --style4 --full --parallax" anim={""} name={"uvod"} animS={"3"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/3727/fee807edf5024982bc86982d0c999ce1_bl=3_ove=000000x15__s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/3727/fee807edf5024982bc86982d0c999ce1_bl=3_ove=000000x15__s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/3727/fee807edf5024982bc86982d0c999ce1_bl=3_ove=000000x15__s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/3727/fee807edf5024982bc86982d0c999ce1_bl=3_ove=000000x15__s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/3727/fee807edf5024982bc86982d0c999ce1_bl=3_ove=000000x15__s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/3727/fee807edf5024982bc86982d0c999ce1_bl=3_ove=000000x15__s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/3727/fee807edf5024982bc86982d0c999ce1_bl=3_ove=000000x15__s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/3727/fee807edf5024982bc86982d0c999ce1_bl=3_ove=000000x15__s=3000x_.png);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex el--1 pt--25 flex--center" anim={null} style={{"maxWidth":1350}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--72 w--600 lh--1" style={{"maxWidth":1000}} content={"<span style=\"color: rgb(255, 242, 0);\">Myslíme zdravotnictví jinak.</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--26" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--40 pt--40" name={"a46ezo6zqss"} layout={"l6"}>
          
          <SeparateLineWrap style={{"backgroundColor":"rgba(255,242,0,1)"}}>
          </SeparateLineWrap>

          <SeparateLineWrap style={{"backgroundColor":"rgba(255,242,0,1)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"sr78unxnr7a"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--62" style={{"maxWidth":596}} content={"<span style=\"color: rgb(255, 242, 0);\">Služby</span>"}>
              </Title>

              <Divider style={{"height":30,"backgroundColor":"rgba(24,122,168,1)"}}>
              </Divider>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--left fs--24" style={{"maxWidth":1000}} content={"<span style=\"color: rgb(77, 217, 255);\">Zprostředkování prodeje či nákupu zdravotnického zařízení od A do Z</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--left fs--13" content={"• ekonomické, právní a daňové poradenství<br>• proces du dilligence (tax, legal, finance DD)<br>• stanovení postupu, příprava veškerých dokumentů včetně SPA a převodních smluv<br>• zajištění advokátní úschovy<br>• zajištěních právních rozborů<br>• valuace zdravotnických zařízení<br>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"<span style=\"color: rgb(77, 217, 255);\">Manažerské poradenství při řízení zdravotnických zařízení</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--left fs--13" content={"• rozvoj efektivního řízení zdravotnických zařízení<br>• vysvětlení úhradových mechanismů a jejich užití v praxi<br>• maximalizace užitku ´hradových mechanismů<br>• manažerské &amp; finanční účetnictví<br>• finanční management&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--left fs--24" style={{"maxWidth":839}} content={"<span style=\"color: rgb(77, 217, 255);\">Vzdělávání v oblasti zdravotnického systému</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--left fs--13" content={"• český systém zdravotnictví - úhradové mechanismy a jejich využití<br>• slovenský systém zdravotnictví - úhradové mechanismy a jejich využití<br>• vybrané systémy zdravotnictví v zemích EU a v USA<br>• souvislosti: plátce - regulátor - poskytoval&nbsp;<br>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left fs--24 mt--20 pt--20" content={"<span style=\"color: rgb(77, 217, 255);\">Manažerský headhunting</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--left fs--13 pt--10" content={"• propojení s vedoucími pracovníky a profesionály ve zdravotnictví<br>• akvizice manažerů s praxí ve zdravotnictví<br>• akvizice erudovaného lékařského a nelékařského personálu<br>• kooperace v řízení vlastními zdroji<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--40 pt--40" name={"7vu2ocdv9ft"} layout={"l6"}>
          
          <SeparateLineWrap style={{"backgroundColor":"rgba(255,242,0,1)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"rv7x8vl5ly"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 242, 0);\">Kdo jsme?</span>"}>
              </Title>

              <Divider style={{"height":30,"backgroundColor":"rgba(24,122,168,1)"}}>
              </Divider>

              <Text className="text-box text-box--justify fs--22" style={{"maxWidth":650}} content={"Jsme zkušený tým manažerů, vedoucích lékařů, odborníků na zdravotnické právo, daňových poradců a dalších profesionálů, kteří se přes 20 let pohybují v oblasti zdravotnictví. Rozumíme detailně nejen českému a slovenskému systému, ale i dalším systémům zdravotnictví v EU či ve světě.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/3727/e8668811daf846dea8b0ba6e5302593d_e=0x0x2121x1414_bri=115_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3727/e8668811daf846dea8b0ba6e5302593d_e=0x0x2121x1414_bri=115_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3727/e8668811daf846dea8b0ba6e5302593d_e=0x0x2121x1414_bri=115_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3727/e8668811daf846dea8b0ba6e5302593d_e=0x0x2121x1414_bri=115_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3727/e8668811daf846dea8b0ba6e5302593d_e=0x0x2121x1414_bri=115_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3727/e8668811daf846dea8b0ba6e5302593d_e=0x0x2121x1414_bri=115_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--40 pt--40" name={"0i2yrecbj5m"} layout={"l6"}>
          
          <SeparateLineWrap style={{"backgroundColor":"rgba(255,242,0,1)"}} fullscreen={false}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--06 pt--06" name={"paticka"} style={{"backgroundColor":"rgba(24,122,168,1)"}}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":476}}>
              
              <Text className="text-box fs--13" content={"<span style=\"color: rgb(255, 255, 32); font-weight: bold;\">Kontaktujte nás</span><br><span style=\"color: var(--white);\">Máte dotaz? Potřebujete poradit? Nebo jen nám chcete něco sdělit? Napište nám pomocí kontaktního formuláře - my se Vám &nbsp;ozveme zpět do 24 hodin.<br></span><span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Text>

              <ContactForm name={"0t7jgqm3dsx"} action={"contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">W&amp;G ASSETS s.r.o.<br>IČO: 10788921<br>DIČ: CZ10788921<br>&nbsp;Nad Obcí II 2074/74, Praha - Krč<br>140 00 Praha<br></span><span style=\"color: var(--color-dominant);\">info@zdravotnictvijinak.cz<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--40 pt--40" name={"0i2yrecbj5m"} layout={"l6"}>
          
          <SeparateLineWrap style={{"backgroundColor":"rgba(255,242,0,1)"}} fullscreen={false}>
          </SeparateLineWrap>

        </SeparateLine>

      </Layout>
    </ThemeWrapper>
  )
}